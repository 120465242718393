<template>
    <loading-spinner v-if="isLoading" :loading="isLoading" class="mt-4"></loading-spinner>
    <div v-if="!isLoading">
        <PickList v-model="variants" dataKey="id" breakpoint="1024px" class="office-picklist"
                  :showSourceControls="false" :showTargetControls="false">
            <!-- Source Header -->
            <template #sourceheader>
                <div class="flex justify-between items-center select-none">
                    <span class="font-bold text-sm">Produkter med <span
                        class="font-black italic text-office-black">{{ currentItem
                        }}</span></span>
                    <span class="text-sm">Antal: {{ variants[0]?.length || 0 }}</span>
                </div>
            </template>

            <!-- Target Header -->
            <template #targetheader>
                <div class="flex justify-between items-center">
                    <div class="select-none">
                        <div class="font-bold text-sm">Produkter med <span v-if="itemType === 'brand'">det
                            nye</span><span v-else>den nye</span> {{ $translate(itemType) }}:
                        </div>
                        <div class="text-sm">Antal valgt: {{ variants[1]?.length || 0 }}</div>
                    </div>
                    <prime-auto-complete v-model="selectedItem" :suggestions="filteredSiblingItems"
                                         dropdown optionLabel="name" forceSelection
                                         :invalid="variants[1]?.length > 0 && !selectedItem"
                                         size="small" :placeholder="'Vælg ' + $translate(itemType)"
                                         class="!bg-office-white"
                                         @complete="search" />

                </div>
            </template>

            <template #option="{ option, selected }" :class="{ 'sold' : option.status === 'sold' }">
                <div class="flex flex-wrap p-1 items-center gap-4 w-full">
                    <div class="flex-1 flex flex-col gap-1">
                        <div class="flex items-center gap-1 justify-between flex-wrap">
                            <div class="flex items-center gap-1 font-medium text-sm relative">
                                <div class="select-none">{{ option.product_name }}</div>
                                <Button @click="goToItem(option)" icon="pi pi-external-link" text rounded
                                        size="small"
                                        severity="secondary" title="Gå til produktside"
                                        aria-label="Gå til produktside"
                                        style="width: 2rem; height: 2rem"></Button>
                            </div>
                            <div class="flex items-center gap-2 select-none">
                                <div class="status-badge topolis-small-badge !text-xxs border border-[#6b7280]"
                                     :class="tagClass(option.status)">
                                    {{ $translate(option.status) }}
                                </div>
                                <div class="font-bold text-sm">
                                    {{ option.price }}
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center flex-wrap gap-1 h-full w-full select-none">
                            <div
                                class="created-at-badge text-gray-800 font-medium w-fit"
                                :class="['text-sm', { 'text-surface-500 dark:text-surface-400': !selected, 'text-inherit': selected }]">
                                {{ option.created_at }}
                            </div>
                            <div v-if="option.size" class="status-badge turquoise-badge">
                                <span class="category-name !text-xs">
                                    {{ option.size }}
                                </span>
                            </div>
                            <div v-if="option.description">
                                <span class="category-name !text-xs">
                                    {{ option.description }}
                                </span>
                            </div>
                            <div v-if="option.colors && option.colors.length > 0" class="flex items-center">
                                <div v-for="color in option.colors">
                                    <color-badge model="color" :show-name="false" :name="color.name"
                                                 :hex="color.hex_code"
                                                 class="mr-1" />
                                </div>
                            </div>
                            <div v-if="option.patterns && option.patterns.length > 0" class="flex items-center gap-1">
                                <div v-for="pattern in option.patterns">
                                    <span class="category-name !text-xs">
                                        {{ pattern.name }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </PickList>
        <loading-button :disabled="variants[1]?.length === 0 || !selectedItem" type="submit"
                        button-type="admin-border-button"
                        :loading="isSaving" @click="editItem" spinner-color="text-office-black"
                        class="small-button mt-4 mx-auto">{{ "Ændre " + $translate(itemType) }}
        </loading-button>
    </div>
</template>
<script>
    import PickList from "primevue/picklist";
    import axios from "axios";
    import ColorBadge from "./ColorBadge.vue";
    import LoadingButton from "./LoadingButton.vue";
    import AutoComplete from "primevue/autocomplete";
    import Button from "primevue/button";
    import LoadingSpinner from "./LoadingSpinner.vue";

    export default {
        name: "SimplePickList",
        components: {
            PickList,
            ColorBadge,
            LoadingButton,
            PrimeAutoComplete: AutoComplete,
            Button,
            LoadingSpinner
        },
        props: {
            itemId: {
                type: [String, Number],
                required: true
            },
            itemType: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                // Initialize with two empty lists
                variants: [[], []],
                isLoading: false,
                isSaving: false, // Add saving state
                currentItem: "",
                siblingItems: [],
                filteredSiblingItems: [],
                selectedItem: null
            };
        },
        methods: {
            async editItem() {
                // Validate that a category is selected
                if (!this.selectedItem) {
                    alert("Please select a category before saving.");
                    return;
                }

                if (this.variants[1].length === 0) {
                    alert("No items in the target list to save.");
                    return;
                }

                // Extract dynamic data for the confirmation message
                const currentItemName = this.currentItem; // Fallback if name is not present
                const newItemName = this.selectedItem?.name || "unknown"; // Fallback if name is not present
                const selectedItemsCount = this.variants[1]?.length || 0;

                // Custom confirmation message
                const confirmationMessage = `Er du sikker på at redigere nuværende (${currentItemName}) til den nye (${newItemName}) for ${selectedItemsCount} produkt(er)?`;

                // Ask for confirmation
                const userConfirmed = confirm(confirmationMessage);
                if (!userConfirmed) {
                    return; // Exit if the user cancels
                }

                this.isSaving = true; // Start saving

                let url;

                if (this.itemType === "category") {
                    url = `/admin/produkter/kategorier/edit-variant-category-to-another`;
                } else if (this.itemType === "brand") {
                    url = `/admin/produkter/mærker/edit-variant-brand-to-another`;
                } else if (this.itemType === "size") {
                    url = `/admin/produkter/størrelser/edit-variant-size-to-another`;
                }

                try {
                    const response = await axios.post(url, {
                        targetVariants: this.variants[1],
                        newItemId: this.selectedItem["id"] // Send the selected item ID
                    });

                    this.emitter.emit("show-toast", {
                        message: "Produkt(er) blev opdateret",
                        type: "success"
                    });

                    // Remove the edited variants from the PickList
                    this.handleVariantsAfterEdit();

                    // Reset the selected data
                    this.selectedItem = null;
                    this.currentItem = null;

                    // Refresh the page after 2 seconds
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);

                } catch (error) {
                    console.error("Error updating item:", error);
                    this.emitter.emit("show-toast", {
                        message: "Varen blev ikke opdateret",
                        type: "error"
                    });
                } finally {
                    this.isSaving = false; // Stop saving
                }
            },
            handleVariantsAfterEdit() {
                // Remove the edited variants from the source list (variants[0]) and the target list (variants[1])
                const editedVariantIds = this.variants[1].map(v => v.id); // Extract IDs of edited variants

                // Filter out the edited variants from the source and target lists
                this.variants[0] = this.variants[0].filter(v => !editedVariantIds.includes(v.id));
                this.variants[1] = []; // Clear the target list because the target variants were moved
            },
            async fetchVariants() {
                this.isLoading = true;

                let url;

                if (this.itemType === "category") {
                    url = `/kategorier/getAllCategoryVariants?itemId=${this.itemId}`;
                } else if (this.itemType === "brand") {
                    url = `/mærker/getAllBrandVariants?itemId=${this.itemId}`;
                } else if (this.itemType === "size") {
                    url = `/størrelser/getAllSizeVariants?itemId=${this.itemId}`;
                }

                console.log("vvv", url);

                try {
                    const response = await axios.get(url);

                    console.log("API Response Data:", response.data);

                    // Populate the source list, current category, and sibling categories
                    this.variants = [response.data.variants, []];
                    this.currentItem = response.data.currentItem; // Current category name
                    this.siblingItems = response.data.siblingItems; // List of sibling categories

                } catch (error) {
                    console.error("Error fetching variants:", error);

                    // Reset source and target arrays on error
                    // Reset states on error
                    this.variants = [[], []];
                    this.currentItem = "";
                    this.siblingItems = [];
                } finally {
                    this.isLoading = false;
                }
            },
            search(event) {
                // Filter siblingItems based on the user's search query
                const query = event.query.toLowerCase();

                this.filteredSiblingItems = this.siblingItems.filter((item) =>
                    item.name.toLowerCase().includes(query)
                );
            },
            goToItem(item) {
                const url = `/admin/produkter/${item.booking_uuid}/${item.product_uuid}/variant/${item.uuid}`;
                window.open(url, "_blank"); // Open the URL in a new tab
            },
            tagClass(status) {
                switch (status) {
                    case "store":
                        return "purple-badge";
                    case "storage":
                        return "yellow-badge";
                    case "collected":
                        return "neon-blue-badge";
                    case "sold":
                        return "lime-badge";
                    case "review":
                        return "amber-badge";
                    case "rejected":
                        return "orange-badge";
                    case "unregistered":
                        return "red-badge";
                    case "none":
                        return "pink-badge";
                    case "unknown":
                        return "gray-badge";
                    case "released":
                        return "sky-badge";
                    case "donated":
                        return "red-cr-badge";
                    default:
                        return "gray-badge";
                }
            }
        },
        mounted() {
            // Fetch variants as soon as the component is mounted
            this.fetchVariants();
        }
    };
</script>

<style>
    .office-picklist .p-listbox-list-container {
        border-top-width: 2px;
        border-bottom-width: 0px;
    }

    .office-picklist .p-listbox-option {
        background: #f5f5f5;
    }

    .office-picklist .p-listbox:not(.p-disabled) .p-listbox-option:not(.p-listbox-option-selected):not(.p-disabled):hover {
        background: #ebf5ff;
    }

    .office-picklist .p-listbox .p-listbox-list .p-listbox-option.p-listbox-option-selected {
        background: #eee8ff;
        color: #5300ab;
    }

    .office-picklist .p-listbox .p-listbox-list .p-listbox-option.p-listbox-option-selected.p-focus {
        background: #e0d5ff;
        color: #501a8c;
    }
</style>