<template>
    <div class="w-full relative divide-y divide-gray-200">
        <div v-for="variant in items" :key="variant.id">
            <a :href="productLink(variant)"
               class="flex hover:no-underline hover:bg-gray-50 hover:text-inherit px-2 py-1.5">
                <div class="flex items-center justify-center"
                     :class="{ 'basis-1/12' : imageSize === 'small', 'basis-4/12 md:basis-2/12' : imageSize === 'medium' }">
                    <div v-if="variant.images[0]">
                        <img :src="variant.images[0].real_image_path" :alt="variant.images[0].alt">
                    </div>
                    <div v-else>
                        <div v-if="variant.product.category.parent_id === 351">
                            <div
                                class="bg-topolis-gray-100 rounded-md w-[80px] h-[80px] p-2 flex items-center justify-center">
                                <img :src="getClothesImage(variant.product)"
                                     :alt="getClothesAlt(variant.product)"
                                     class="bg-no-repeat">
                            </div>
                        </div>
                        <div v-else-if="variant.product.category.parent_id === 213"
                             class="bg-topolis-gray-100 rounded-md w-[80px] h-[80px] p-2 flex items-center justify-center mx-auto">
                            <img src="/assets/categories/toys.svg"
                                 alt="minitopolis-no-image-toy-placeholder"
                                 class="bg-no-repeat">
                        </div>
                        <div v-else-if="variant.product.category.parent_id === 144"
                             class="bg-topolis-gray-100 rounded-md w-[80px] h-[80px] p-2 flex items-center justify-center">
                            <img :src="getEquipmentImage(variant.product)"
                                 :alt="getEquipmentAlt(variant.product)"
                                 class="bg-no-repeat">
                        </div>
                        <div v-else-if="variant.product.category.parent_id === 221"
                             class="bg-topolis-gray-100 rounded-md w-[80px] h-[80px] p-2 flex items-center justify-center">
                            <img src="/assets/categories/jacket.svg"
                                 alt="minitopolis-no-image-jacket-placeholder"
                                 class="bg-no-repeat">
                        </div>
                        <div v-else-if="variant.product.category.parent_id === 620"
                             class="bg-topolis-gray-100 rounded-md w-[80px] h-[80px] p-2 flex items-center justify-center">
                            <img :src="getInteriorImage(variant.product)"
                                 :alt="getInteriorAlt(variant.product)"
                                 class="bg-no-repeat">
                        </div>
                        <div v-else-if="variant.product.category.parent_id === 376"
                             class="bg-topolis-gray-100 rounded-md w-[80px] h-[80px] p-2 flex items-center justify-center">
                            <img :src="getAccessoriesImage(variant.product)"
                                 :alt="getAccessoriesAlt(variant.product)"
                                 class="bg-no-repeat">
                        </div>
                        <div v-else-if="variant.product.category.parent_id === 621"
                             class="bg-topolis-gray-100 rounded-md w-[80px] h-[80px] p-2 flex items-center justify-center">
                            <img src="/assets/categories/footwear.svg"
                                 alt="minitopolis-no-image-footwear-placeholder"
                                 class="bg-no-repeat">
                        </div>
                        <div v-else-if="variant.product.category.parent_id === 377"
                             class="bg-topolis-gray-100 rounded-md w-[80px] h-[80px] p-2 flex items-center justify-center mx-auto">
                            <img src="/assets/categories/books-games.svg"
                                 alt="minitopolis-no-image-books-games-placeholder"
                                 class="bg-no-repeat">
                        </div>
                    </div>
                </div>
                <div class="flex justify-between text-left w-full ml-3 my-1">
                    <div class="flex flex-col justify-between gap-1 leading-3 mr-4">
                        <p class="inline-flex font-medium capitalize">{{ variant.product.pretty_name }}</p>
                        <div class="flex items-center flex-wrap gap-2">
                            <div v-if="variant.colors && variant.colors.length"
                                 class="text-gray-500 inline-flex items-center flex-wrap w-fit">
                                <div v-for="color in variant.colors">
                                    <tooltip>
                                        <template v-slot:triggerText>
                                            <color-badge model="color" :show-name="false" :name="color.name"
                                                         :hex="color.hex_code"
                                                         class="mr-1" />
                                        </template>
                                        <span class="font-light">Farve: <span
                                            class="font-bold">{{ $translate(color.name)
                                            }}</span></span>
                                    </tooltip>
                                </div>
                            </div>
                            <div v-if="variant.patterns && variant.patterns.length"
                                 class="text-gray-500 inline-flex items-center flex-wrap w-fit">
                                <div v-for="pattern in variant.patterns">
                                    <tooltip>
                                        <template v-slot:triggerText>
                                            <div class="text-xs mr-2">{{ pattern.name }}</div>
                                        </template>
                                        <span class="font-light">Mønster: <span
                                            class="font-bold">{{ pattern.name }}</span></span>
                                    </tooltip>
                                </div>
                            </div>
                        </div>

                        <div v-if="variant.size" class="flex items-center gap-1">
                            <!--                            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-5 -rotate-45"-->
                            <!--                                 viewBox="0 0 24 24">-->
                            <!--                                <g transform="translate(-620 -188)">-->
                            <!--                                    <path d="M0,10H20V0H0S0,8.612,0,10Z" transform="translate(622 195)" fill="#292d32"-->
                            <!--                                          opacity="0.2" />-->
                            <!--                                    <path d="M1.5,0V5a.755.755,0,0,1-.75.75A.755.755,0,0,1,0,5V0Z"-->
                            <!--                                          transform="translate(637.25 195)" fill="#292d32" />-->
                            <!--                                    <path d="M1.5,0V3.8a.755.755,0,0,1-.75.75A.755.755,0,0,1,0,3.8V0Z"-->
                            <!--                                          transform="translate(625.25 195)" fill="#292d32" />-->
                            <!--                                    <path d="M1.54,0,1.5,5.01a.747.747,0,0,1-.75.74H.74A.749.749,0,0,1,0,4.99L.04,0Z"-->
                            <!--                                          transform="translate(629.25 195)" fill="#292d32" />-->
                            <!--                                    <path d="M1.5,0V2.6a.755.755,0,0,1-.75.75A.755.755,0,0,1,0,2.6V0Z"-->
                            <!--                                          transform="translate(633.25 195)" fill="#292d32" />-->
                            <!--                                </g>-->
                            <!--                            </svg>-->
                            <p class="text-sm leading-3">{{ "Str. " + variant.size.name }}</p>
                        </div>
                        <!--                        <p class="inline-flex font-medium">{{ variant.product.quantity }} stk.</p>-->
                    </div>
                    <div v-if="variant.discount" class="flex flex-col items-end">
                        <div class="flex">
                            <p class="text-sm xs:text-base font-medium text-red-500">
                                <price :price="variant.discounted_price"></price>
                            </p>
                        </div>
                        <div class="flex items-center -mt-0.5">
                            <p class="text-gray-500 line-through text-xs mr-1">
                                <price :price="variant.price"></price>
                            </p>
                            <p class="text-xxs xs:text-xs text-red-500 font-medium">-{{ variant.discount }}%</p>
                        </div>
                    </div>
                    <div v-else class="flex text-sm xs:text-base font-medium text-topolis-blue-700 mr-2 xs:mr-0">
                        <price :price="variant.price"></price>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
    import Price from "./Price.vue";
    import ColorBadge from "../Pages/Common/ColorBadge.vue";
    import Tooltip from "../Pages/Tooltip.vue";

    export default {
        name: "product-list",
        components: { Tooltip, Price, ColorBadge },
        props: {
            items: {},
            imageSize: {
                type: String,
                required: false,
                default: "small"
            }
        },
        data() {
            return {
                timer: null
            };
        },
        methods: {
            productLink(variant) {
                return `/shop/${variant.product.target_segment.name === "children" ? "boern" : variant.product.target_segment.name}/${variant.product.category.parent.slug}/${variant.product.category.slug}/${variant.slug}`;
            },
            getClothesImage(product) {
                if (product.target_segment) {
                    return product.target_segment === "mama"
                        ? "/assets/categories/mama-clothes.svg"
                        : "/assets/categories/clothes.svg"; // Default to 'children' or fallback
                }
                return "/assets/categories/clothes.svg"; // Default fallback
            },
            getClothesAlt(product) {
                if (product.target_segment) {
                    return product.target_segment === "mama"
                        ? "MAMA tøj"
                        : "Børnetøj"; // Default to 'children' or fallback
                }
                return "MAMA tøj"; // Default fallback
            },
            getEquipmentImage(product) {
                if (product.target_segment) {
                    return product.target_segment === "mama"
                        ? "/assets/categories/belt.svg"
                        : "/assets/categories/equipment.svg"; // Default to 'children' or fallback
                }
                return "/assets/categories/equipment.svg"; // Default fallback
            },
            getEquipmentAlt(product) {
                if (product.target_segment) {
                    return product.target_segment === "mama"
                        ? "Udstyr til MAMA"
                        : "Udstyr til børn"; // Default to 'children' or fallback
                }
                return "Udstyr til børn"; // Default fallback
            },
            getAccessoriesImage(product) {
                if (product.target_segment) {
                    return product.target_segment === "mama"
                        ? "/assets/categories/mama-accessories.svg"
                        : "/assets/categories/accessories.svg"; // Default to 'children' or fallback
                }
                return "/assets/categories/accessories.svg"; // Default fallback
            },
            getAccessoriesAlt(product) {
                if (product.target_segment) {
                    return product.target_segment === "mama"
                        ? "Accessories til MAMA"
                        : "Accessories til børn"; // Default to 'children' or fallback
                }
                return "Accessories til børn"; // Default fallback
            },
            getInteriorImage(product) {
                if (product.target_segment) {
                    return product.target_segment === "mama"
                        ? "/assets/categories/mama-interior.svg"
                        : "/assets/categories/interior.svg"; // Default to 'children' or fallback
                }
                return "/assets/categories/interior.svg"; // Default fallback
            },
            getInteriorAlt(product) {
                if (product.target_segment) {
                    return product.target_segment === "mama"
                        ? "Interiør til MAMA"
                        : "Interiør til børn"; // Default to 'children' or fallback
                }
                return "Interiør til børn"; // Default fallback
            }
        }
    };
</script>
