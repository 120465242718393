<template>
    <div v-if="inGrid && visitors" class="absolute z-30 top-5 left-0">
        <div class="bg-white bg-opacity-[.50] leading-3 p-1 rounded-r-lg">
            <span class="inline-flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-4 text-gray-800"
                     fill="none"
                     viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
                <span class="text-xs font-medium ml-0.5 mr-1 text-gray-800">{{ visitors }}</span>
                <span class="active-circle"></span>
            </span>
        </div>
    </div>
    <transition name="bounce" appear>
        <div v-if="!inGrid && visitors" class="currently-browsing">
            <span class="font-semibold">{{ visitors }}</span> {{ visitors === 1 ? "anden" : "andre" }} kigger i
            øjeblikket på denne vare.
        </div>
    </transition>
</template>
<script>
    import * as FingerprintJS from "@fingerprintjs/fingerprintjs";

    export default {
        data() {
            return {
                visitors: null,
                interval: null,
                clientId: null
            };
        },
        props: {
            productUuid: {},
            variantUuid: {},
            inGrid: {
                type: Boolean,
                default: false
            }
        },
        created() {
            // Not part of the 1st version of the product
            if (document.visibilityState === "visible") {
                const fpPromise = FingerprintJS.load({
                    monitoring: false
                });

                fpPromise
                    .then(fp => fp.get())
                    .then(result => {
                        // This is the visitor identifier:
                        this.clientId = result.visitorId;
                        this.registerBrowsing();
                    });
            }


            document.addEventListener("visibilitychange", this.visibilityChange, false);
            window.addEventListener("beforeunload", this.visibilityChange);
        },
        methods: {
            registerBrowsing() {
                axios.post("/register-browsing-product", {
                    productUuid: this.productUuid,
                    variantUuid: this.variantUuid,
                    clientId: this.clientId
                }).then(() => {
                    this.interval = setInterval(() => {
                        axios.get("/stream/" + this.productUuid + "/" + this.variantUuid + "?clientId=" + this.clientId).then((response) => {
                            this.visitors = response.data.visitors;
                        });
                    }, 6000);
                });
            },
            visibilityChange() {
                const vm = this;

                const data = new FormData();
                data.append("productUuid", vm.productUuid);
                data.append("variantUuid", vm.variantUuid);
                data.append("clientId", this.clientId);

                if (document.visibilityState === "hidden") {
                    window.navigator.sendBeacon("/remove-browsing-product", data);
                    clearInterval(this.interval);
                } else {
                    this.registerBrowsing();
                }
            }
        }
    };
</script>

<style>
    .currently-browsing {
        background: #b05738;
        color: #ffffff;
        padding: 8px 12px;
        font-size: 14px;
        z-index: 99999999;
        @apply shadow-lg rounded-md mb-2;
    }

    .bounce-enter-active {
        animation: bounce-in .3s;
    }

    .bounce-leave-active {
        animation: bounce-in .3s reverse;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
</style>
