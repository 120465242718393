<template>
    <div class="shop-product-card" :class="{ 'shop-product-sold-card' : variant.sold_at }">
        <a class="shop-product-image-container xl:max-w-[250px]" :href="productLink">
            <div v-if="productHasImages">
                <img :srcset="srcSetString(defaultImageSet)"
                     sizes="(max-width: 600px) 600px, (max-width: 800px) 800px, 1200px"
                     :src="defaultImageSet.default.real_image_path"
                     :alt="defaultImageSet.default.alt"
                     class="shop-top-image"
                >
                <img :srcset="srcSetString(hoverImageSet)"
                     sizes="(max-width: 600px) 600px, (max-width: 800px) 800px, 1200px"
                     :src="hoverImageSet.default.real_image_path"
                     :alt="hoverImageSet.default.alt"
                     class="shop-second-image"
                >
            </div>
            <div v-else>
                <div v-if="newProducts">
                    <div v-if="variant.product.category.parent_id === 351">
                        <img :src="clothesImage"
                             :alt="clothesAlt"
                             class="shop-top-image">
                        <img :src="clothesImage"
                             :alt="clothesAlt"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.parent_id === 213">
                        <img src="/assets/shop/minitopolis-toy-placeholder.jpg"
                             alt="minitopolis-no-image-toy-placeholder"
                             class="shop-top-image">
                        <img src="/assets/shop/minitopolis-toy-placeholder.jpg"
                             alt="minitopolis-no-image-toy-placeholder"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.parent_id === 221">
                        <img src="/assets/shop/minitopolis-warm-clothing-placeholder.jpg"
                             alt="minitopolis-no-image-warm-clothing-placeholder"
                             class="shop-top-image">
                        <img src="/assets/shop/minitopolis-warm-clothing-placeholder.jpg"
                             alt="minitopolis-no-image-warm-clothing-placeholder"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.parent_id === 621">
                        <img src="/assets/shop/minitopolis-shoes-placeholder.jpg"
                             alt="minitopolis-no-image-shoes-placeholder"
                             class="shop-top-image">
                        <img src="/assets/shop/minitopolis-shoes-placeholder.jpg"
                             alt="minitopolis-no-image-shoes-placeholder"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.parent_id === 376">
                        <img :src="accessoriesImage"
                             :alt="accessoriesAlt"
                             class="shop-top-image">
                        <img :src="accessoriesImage"
                             :alt="accessoriesAlt"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.parent_id === 377">
                        <img src="/assets/shop/minitopolis-books-games-placeholder.jpg"
                             alt="minitopolis-no-image-books-games-placeholder"
                             class="shop-top-image">
                        <img src="/assets/shop/minitopolis-books-games-placeholder.jpg"
                             alt="minitopolis-no-image-books-games-placeholder"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.parent_id === 144">
                        <img :src="equipmentImage"
                             :alt="equipmentAlt"
                             class="shop-top-image">
                        <img :src="equipmentImage"
                             :alt="equipmentAlt"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.parent_id === 620">
                        <img :src="interiorImage"
                             :alt="interiorAlt"
                             class="shop-top-image">
                        <img :src="interiorImage"
                             :alt="interiorAlt"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.id === 132 || variant.product.category.id === 378">
                        <img src="/assets/shop/minitopolis-other-placeholder.jpg"
                             alt="minitopolis-no-image-other-placeholder"
                             class="shop-top-image">
                        <img src="/assets/shop/minitopolis-other-placeholder.jpg"
                             alt="minitopolis-no-image-other-placeholder"
                             class="shop-second-image">
                    </div>
                </div>
                <div v-else>
                    <div v-if="variant.product.category.parent_id === 351">
                        <img :src="clothesImage"
                             :alt="clothesAlt"
                             class="shop-top-image">
                        <img :src="clothesImage"
                             :alt="clothesAlt"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.parent_id === 213">
                        <img src="/assets/shop/minitopolis-toy-placeholder-light-gray.jpg"
                             alt="minitopolis-no-image-toy-placeholder"
                             class="shop-top-image">
                        <img src="/assets/shop/minitopolis-toy-placeholder-light-gray.jpg"
                             alt="minitopolis-no-image-toy-placeholder"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.parent_id === 221">
                        <img src="/assets/shop/minitopolis-warm-clothing-placeholder-light-gray.jpg"
                             alt="minitopolis-no-image-warm-clothing-placeholder"
                             class="shop-top-image">
                        <img src="/assets/shop/minitopolis-warm-clothing-placeholder-light-gray.jpg"
                             alt="minitopolis-no-image-warm-clothing-placeholder"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.parent_id === 621">
                        <img src="/assets/shop/minitopolis-shoes-placeholder-light-gray.jpg"
                             alt="minitopolis-no-image-shoes-placeholder"
                             class="shop-top-image">
                        <img src="/assets/shop/minitopolis-shoes-placeholder-light-gray.jpg"
                             alt="minitopolis-no-image-shoes-placeholder"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.parent_id === 376">
                        <img :src="accessoriesImage"
                             :alt="accessoriesAlt"
                             class="shop-top-image">
                        <img :src="accessoriesImage"
                             :alt="accessoriesAlt"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.parent_id === 377">
                        <img src="/assets/shop/minitopolis-books-games-placeholder-light-gray.jpg"
                             alt="minitopolis-no-image-books-games-placeholder"
                             class="shop-top-image">
                        <img src="/assets/shop/minitopolis-books-games-placeholder-light-gray.jpg"
                             alt="minitopolis-no-image-books-games-placeholder"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.parent_id === 144">
                        <img :src="equipmentImage"
                             :alt="equipmentAlt"
                             class="shop-top-image">
                        <img :src="equipmentImage"
                             :alt="equipmentAlt"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.parent_id === 620">
                        <img :src="interiorImage"
                             :alt="interiorAlt"
                             class="shop-top-image">
                        <img :src="interiorImage"
                             :alt="interiorAlt"
                             class="shop-second-image">
                    </div>
                    <div v-if="variant.product.category.id === 132 || variant.product.category.id === 378">
                        <img src="/assets/shop/minitopolis-other-placeholder-light-gray.jpg"
                             alt="minitopolis-no-image-other-placeholder"
                             class="shop-top-image">
                        <img src="/assets/shop/minitopolis-other-placeholder-light-gray.jpg"
                             alt="minitopolis-no-image-other-placeholder"
                             class="shop-second-image">
                    </div>
                </div>
            </div>
            <!--            <browsing-product :in-grid="true" product-uuid="{{ $variant->product->uuid }}"-->
            <!--                              variant-uuid="{{ $variant->uuid }}"></browsing-product>-->
            <!-- Tags-->
            <div class="absolute z-30 top-5 right-0">
                <div v-if="variant.tags && variant.tags.length"
                     class="inline-flex items-center justify-end flex-wrap gap-1">
                    <span v-for="tag in variant.tags" :key="tag.value"
                          class="font-medium inline-flex items-center w-fit gap-1">
                        <tag-badge :name="tag.name"
                                   :bg-color="tag.bg_color"
                                   :text-color="tag.text_color"
                                   :border-color="tag.border_color"
                                   size="mini"></tag-badge>
                    </span>
                </div>
            </div>
            <!-- If Sold-->
            <div class="absolute z-30 bottom-0 left-0 right-0 w-full">
                <div v-if="variant.sold_at"
                     class="bg-stone-300 text-center text-xs text-black font-medium p-1">
                    <span class="font-light">Solgt d.</span> {{ formatDate(variant.sold_at) }}
                </div>
            </div>
            <div class="shop-product-card-buttons">
                <div class="flex items-center justify-center w-full gap-2">
                    <!--                Quick view / Open product in modal-->
                    <!--                <div class="shop-button p-1.5 xs:p-2.5 flex items-center justify-between" title="Quick view">-->
                    <!--                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">-->
                    <!--                        <g transform="translate(-108 -188)">-->
                    <!--                            <path d="M7.16,3.58A3.58,3.58,0,1,1,3.58,0,3.576,3.576,0,0,1,7.16,3.58Z"-->
                    <!--                                  transform="translate(116.42 196.42)" fill="none" stroke="currentColor"-->
                    <!--                                  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />-->
                    <!--                            <path-->
                    <!--                                d="M9.785,16.55c3.53,0,6.82-2.08,9.11-5.68a5.326,5.326,0,0,0,0-5.19C16.6,2.08,13.315,0,9.785,0S2.965,2.08.675,5.68a5.326,5.326,0,0,0,0,5.19C2.965,14.47,6.255,16.55,9.785,16.55Z"-->
                    <!--                                transform="translate(110.215 191.72)" fill="none" stroke="currentColor"-->
                    <!--                                stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />-->
                    <!--                        </g>-->
                    <!--                    </svg>-->
                    <!--                </div>-->
                    <add-product-button :product="variant.product" :variant="variant"
                                        button-type="small"></add-product-button>
                    <!--                Add to wishlist button-->
                    <add-product-to-list-button :product="variant.product" :variant="variant" :authenticated="true"
                                                :favorited="true" button-type="small"></add-product-to-list-button>
                </div>
            </div>
        </a>
        <!-- Product details and variants -->
        <div class="mt-2 flex items-center w-full mx-auto px-0.5">
            <div class="flex flex-col justify-between w-full leading-3">
                <a v-if="variant.product.brand" :href="brandLink" class="line-clamp-1 mb-1">
                    <span class="font-bold text-xs capitalize">{{ variant.product.brand.name }}</span>
                </a>
                <a v-if="variant.product.category.parent && variant.product.category.parent.id === 377" :href="categoryLink" class="line-clamp-1 mb-1">
                    <span class="font-bold text-xs capitalize">{{ variant.product.category.name }}</span>
                </a>
                <!-- 'a' tag href will use the router-link or nuxt-link instead with the correct route to the product -->
                <a :href="productLink" class="line-clamp-1 mb-1">
                    <span class="font-medium text-sm capitalize">{{ variant.product.pretty_name }}</span>
                </a>
                <!-- Product variants, use v-for to loop through your variants -->
                <div class="flex flex-col gap-2">
                    <div class="flex items-center gap-4">
                        <div v-if="variant.size" class="flex items-center gap-1">
                            <!--                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 min-w-0 w-5 -rotate-45"-->
                            <!--                             viewBox="0 0 24 24">-->
                            <!--                            <g transform="translate(-620 -188)">-->
                            <!--                                <path d="M0,10H20V0H0S0,8.612,0,10Z" transform="translate(622 195)" fill="#292d32"-->
                            <!--                                      opacity="0.2" />-->
                            <!--                                <path d="M1.5,0V5a.755.755,0,0,1-.75.75A.755.755,0,0,1,0,5V0Z"-->
                            <!--                                      transform="translate(637.25 195)" fill="#292d32" />-->
                            <!--                                <path d="M1.5,0V3.8a.755.755,0,0,1-.75.75A.755.755,0,0,1,0,3.8V0Z"-->
                            <!--                                      transform="translate(625.25 195)" fill="#292d32" />-->
                            <!--                                <path d="M1.54,0,1.5,5.01a.747.747,0,0,1-.75.74H.74A.749.749,0,0,1,0,4.99L.04,0Z"-->
                            <!--                                      transform="translate(629.25 195)" fill="#292d32" />-->
                            <!--                                <path d="M1.5,0V2.6a.755.755,0,0,1-.75.75A.755.755,0,0,1,0,2.6V0Z"-->
                            <!--                                      transform="translate(633.25 195)" fill="#292d32" />-->
                            <!--                            </g>-->
                            <!--                        </svg>-->
                            <p class="text-sm leading-3 font-medium">
                                <span class="font-normal">str. </span>
                                {{ variant.size.name }}
                            </p>
                        </div>
                        <div v-if="variant.colors && variant.colors.length" class="flex items-center flex-wrap">
                            <div v-for="color in variant.colors">
                                <tooltip>
                                    <template v-slot:triggerText>
                                        <color-badge model="color" :show-name="false" :name="color.name"
                                                     :hex="color.hex_code"
                                                     class="mr-1" />
                                    </template>
                                    <span class="font-light">Farve: <span
                                        class="font-bold">{{ $translate(color.name)
                                        }}</span></span>
                                </tooltip>
                            </div>
                        </div>
                        <div v-if="variant.product.category.parent && variant.product.category.parent.id === 377 && variant.pretty_description">
                            <div class="line-clamp-2">{{ variant.pretty_description }}</div>
                        </div>
                    </div>
                    <div v-if="variant.discount" class="flex flex-col">
                        <div class="flex">
                            <p class="text-sm xs:text-base font-bold text-black">
                                <price :price="variant.discounted_price"></price>
                            </p>
                        </div>
                        <div class="flex items-center flex-wrap -mt-0.5">
                            <p class="text-gray-800 text-xs mr-1">Oprindeligt:</p>
                            <p class="text-gray-800 line-through text-xs mr-1">
                                <price :price="variant.price"></price>
                            </p>
                            <p class="text-xxs xs:text-xs text-gray-800 font-medium">-{{ variant.discount }}%</p>
                        </div>
                    </div>
                    <div v-else class="flex text-sm xs:text-base font-bold text-black mr-2 xs:mr-0">
                        <price :price="variant.price"></price>
                    </div>
                    <div v-if="!variant.is_shippable" class="flex items-center gap-1 text-gray-700">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="flex-shrink-0 min-w-0 w-4 h-4 text-gray-700">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
                        </svg>
                        <div class="text-black text-xs">Kan kun afhentes i butik</div>
                    </div>
                </div>

                <!-- Display price and discount based on the variant -->
                <!-- You may have to adjust your data structure for easy access to price and discounts -->

                <!-- Variant images -->
                <!-- Also with v-for loop for displaying each of your variants' images -->
                <!--                <div class="mt-2 flex items-center gap-2" v-if="productHasImages">-->
                <!--                    <div class="rounded bg-zinc-50 w-20 h-16 border-2 border-topolis-bright-purple-300">-->
                <!--                        {{ variant.product }}-->
                <!--                        <img :src="v.image.real_image_path"-->
                <!--                             alt="Sample description"-->
                <!--                             class="shop-top-image rounded"-->
                <!--                             v-for="(v, index) in variant.product"-->
                <!--                             :key="index" />-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <div class="mt-2 flex items-center gap-2">-->
                <!--                    <div class="rounded bg-zinc-50 w-[30px] h-[40px] border-2 border-topolis-bright-purple-300">-->
                <!--                        <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg"-->
                <!--                             alt="Front of men&#039;s Basic Tee in black." class="shop-top-image rounded">-->
                <!--                    </div>-->
                <!--                    <div class="rounded bg-zinc-800 w-[30px] h-[40px]">-->
                <!--                        <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-04.jpg"-->
                <!--                             alt="Front of men&#039;s Basic Tee in black." class="shop-top-image rounded">-->
                <!--                    </div>-->
                <!--                    <div class="rounded bg-zinc-500 w-[30px] h-[40px]">-->
                <!--                        <img src="https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-03.jpg"-->
                <!--                             alt="Front of men&#039;s Basic Tee in black." class="shop-top-image rounded">-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import Price from "./Price.vue";
    import TagBadge from "../Pages/Common/TagBadge.vue";
    import ColorBadge from "../Pages/Common/ColorBadge.vue";
    import AddProductButton from "./AddProductButton.vue";
    import AddProductToListButton from "./AddProductToListButton.vue";
    import { isGecko } from "@fingerprintjs/fingerprintjs";
    import Tooltip from "../Pages/Tooltip.vue";
    import BrowsingProduct from "./BrowsingProduct.vue";

    export default {
        components: { Tooltip, Price, TagBadge, AddProductButton, AddProductToListButton, ColorBadge, BrowsingProduct },
        props: {
            variant: {},
            newProducts: {
                type: Boolean,
                required: false,
                default: false
            },
            currentSegment: {
                type: String,
                required: false,
                default: "boern"
            },
            adminPage: {
                type: Boolean,
                required: false,
                default: false
            },
            isOnWebshop: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                groupedImages: {}, // to store the grouped images
                defaultImageSet: {
                    default: null,
                    medium: null,
                    mobile: null
                },
                hoverImageSet: {
                    default: null,
                    medium: null,
                    mobile: null
                },
                defaultImage: null,
                mediumImage: null,
                mobileImage: null
            };
        },
        computed: {
            productLink() {
                if (this.variant.product.category_id === 132 || this.variant.product.category_id === 378) {
                    return `/shop/${this.currentSegment}/${this.variant.product.category.slug}/${this.variant.slug}`;
                } else {
                    if (this.variant.product.category.parent) {
                        return `/shop/${this.currentSegment}/${this.variant.product.category.parent.slug}/${this.variant.product.category.slug}/${this.variant.slug}`;
                    }
                }
            },
            brandLink() {
                if (this.variant.product.brand) {
                    return `/shop/${this.currentSegment}/brands/${this.variant.product.brand.slug}`;
                }
            },
            categoryLink() {
                if (this.variant.product.category.parent === null) {
                    return `/shop/${this.currentSegment}/${this.variant.product.category.slug}`;
                } else {
                    return `/shop/${this.currentSegment}/${this.variant.product.category.parent.slug}/${this.variant.product.category.slug}`;
                }
            },
            productHasImages() {
                return !!this.defaultImageSet.default && !!this.hoverImageSet.default;
            },
            srcSetString() {
                return (imageSet) => {
                    const { default: defaultImg, medium, mobile } = imageSet;
                    return `${defaultImg.real_image_path} 1200w, ${medium.real_image_path} 800w, ${mobile.real_image_path} 600w`;
                };
            },
            clothesImage() {
                if (this.variant.product.target_segment) {
                    if (this.newProducts) {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-clothing-mama-placeholder.jpg"
                            : "/assets/shop/minitopolis-clothing-placeholder.jpg"; // Default to 'children' or fallback
                    } else {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-clothing-mama-placeholder-light-gray.jpg"
                            : "/assets/shop/minitopolis-clothing-placeholder-light-gray.jpg"; // Default to 'children' or fallback
                    }

                }
                return "/assets/shop/minitopolis-clothing-placeholder.jpg"; // Default fallback
            },
            clothesAlt() {
                if (this.variant.product.target_segment) {
                    return this.variant.product.target_segment === "mama"
                        ? "MAMA tøj"
                        : "Børnetøj"; // Default to 'children' or fallback
                }
                return "MAMA tøj"; // Default fallback
            },
            equipmentImage() {
                if (this.variant.product.target_segment) {
                    if (this.newProducts) {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-equipment-mama-placeholder.jpg"
                            : "/assets/shop/minitopolis-equipment-placeholder.jpg"; // Default to 'children' or fallback
                    } else {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-equipment-mama-placeholder-light-gray.jpg"
                            : "/assets/shop/minitopolis-equipment-placeholder-light-gray.jpg"; // Default to 'children' or fallback
                    }
                }
                return "/assets/shop/minitopolis-equipment-placeholder.jpg"; // Default fallback
            },
            equipmentAlt() {
                if (this.variant.product.target_segment) {
                    return this.variant.product.target_segment === "mama"
                        ? "Udstyr til MAMA"
                        : "Udstyr til børn"; // Default to 'children' or fallback
                }
                return "Udstyr til børn"; // Default fallback
            },
            accessoriesImage() {
                if (this.variant.product.target_segment) {
                    if (this.newProducts) {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-accessories-mama-placeholder.jpg"
                            : "/assets/shop/minitopolis-accessories-placeholder.jpg"; // Default to 'children' or fallback
                    } else {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-accessories-mama-placeholder-light-gray.jpg"
                            : "/assets/shop/minitopolis-accessories-placeholder-light-gray.jpg"; // Default to 'children' or fallback
                    }
                }
                return "/assets/shop/minitopolis-accessories-placeholder.jpg"; // Default fallback
            },
            accessoriesAlt() {
                if (this.variant.product.target_segment) {
                    return this.variant.product.target_segment === "mama"
                        ? "Accessories til MAMA"
                        : "Accessories til børn"; // Default to 'children' or fallback
                }
                return "Accessories til børn"; // Default fallback
            },
            interiorImage() {
                if (this.variant.product.target_segment) {
                    if (this.newProducts) {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-interior-mama-placeholder.jpg"
                            : "/assets/shop/minitopolis-interior-placeholder.jpg"; // Default to 'children' or fallback
                    } else {
                        return this.variant.product.target_segment === "mama"
                            ? "/assets/shop/minitopolis-interior-mama-placeholder-light-gray.jpg"
                            : "/assets/shop/minitopolis-interior-placeholder-light-gray.jpg"; // Default to 'children' or fallback
                    }
                }
                return "/assets/shop/minitopolis-interior-placeholder.jpg"; // Default fallback
            },
            interiorAlt() {
                if (this.variant.product.target_segment) {
                    return this.variant.product.target_segment === "mama"
                        ? "Interiør til MAMA"
                        : "Interiør til børn"; // Default to 'children' or fallback
                }
                return "Interiør til børn"; // Default fallback
            }
        },
        created() {
            console.log(this.variant);
            console.log(this.variant.grouped_images); // For debugging

            // Assign grouped images to the component's data
            this.groupedImages = this.variant.grouped_images;

            const groupIds = Object.keys(this.groupedImages);

            if (groupIds.length >= 2) {
                let [firstGroupImages, secondGroupImages] = [
                    this.groupedImages[groupIds[0]],
                    this.groupedImages[groupIds[1]]
                ];

                // Sort images by 'order'
                firstGroupImages = firstGroupImages.sort((a, b) => a.order - b.order);
                secondGroupImages = secondGroupImages.sort((a, b) => a.order - b.order);

                // Loop to assign images for the first set (default view)
                firstGroupImages.forEach((image) => {
                    switch (image.responsive_size) {
                        case "default":
                            this.hoverImageSet.default = image;
                            break;
                        case "medium":
                            this.hoverImageSet.medium = image;
                            break;
                        case "mobile":
                            this.hoverImageSet.mobile = image;
                            break;
                    }
                });

                // Loop to assign images for the second set (hover view)
                secondGroupImages.forEach((image) => {
                    switch (image.responsive_size) {
                        case "default":
                            this.defaultImageSet.default = image;
                            break;
                        case "medium":
                            this.defaultImageSet.medium = image;
                            break;
                        case "mobile":
                            this.defaultImageSet.mobile = image;
                            break;
                    }
                });
            }
        },
        methods: {
            formatDate(dateString) {
                let date = new Date(dateString);
                let day = date.getDate();
                let monthIndex = date.getMonth();
                let year = date.getFullYear();
                let hours = date.getHours();
                let minutes = date.getMinutes();

                // Format the date
                day = day < 10 ? "0" + day : day;
                // Danish month names
                const months = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];
                monthIndex = months[monthIndex];
                hours = hours < 10 ? "0" + hours : hours;
                minutes = minutes < 10 ? "0" + minutes : minutes;

                return day + ". " + monthIndex + " " + hours + ":" + minutes;
            }
        }
    };

</script>
