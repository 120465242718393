<template>
    <button :data-cy="dataCy" :class="buttonType" :disabled="disabled || loading" class="relative">
        <span v-if="loading" class="button-spinner">
            <svg v-show="loading" class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24" :class="spinnerColor">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </span>
        <span v-if="!loading">
            <slot />
        </span>
    </button>
</template>

<script>
    export default {
        name: "LoadingButton",
        components: {},
        props: {
            loading: {
                type: Boolean
            },
            buttonType: {
                type: String,
                default: "button"
            },
            dataCy: {
                type: String,
                required: false
            },
            disabled: {
                type: Boolean
            },
            spinnerColor: {
                type: String,
                default: "text-white"
            }
        }
    };
</script>
